import { ReducerType, ActionType, StateType } from './index';
import storage from '../../extensions/storage';
import { 
  GET_FAVORITES_ACTION, 
  ADD_ITEM_TO_FAVORITES_ACTION, 
  REPLACE_ALL_FAVORITE_ITEMS, 
  REMOVE_FAVORITE_ACTION 
} from '../actions/types';
import { IKeyValueObject } from '@temsa/interfaces/generic';

const defaultState = JSON.parse(storage.getItem('favorites') || '{}');

const favorites: ReducerType<StateType, any> = (state: StateType = defaultState, action: ActionType) => {
  
  switch(action.type) {
    case GET_FAVORITES_ACTION: {
      return {...state};
    }
    case ADD_ITEM_TO_FAVORITES_ACTION: {
      return {
        ...state,
        ...action.data
      }
    }
    case REMOVE_FAVORITE_ACTION: {
      return {
        ...state,
        [action.region]: [...(state[action.region].filter((fav: IKeyValueObject) => fav.id !== action.id))]
      }
    }
    case REPLACE_ALL_FAVORITE_ITEMS: {
      return [
        ...action.data
      ]
    }
    default:
      return state;
  }
}

export default favorites;