import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect, Provider } from 'react-redux'
import { IKeyValueObject } from '@temsa/interfaces/generic';
import container from './../../reducers';
import { get } from '@temsa/utils';
import { win } from '@temsa/helpers/globals';
import { showToast } from '@temsa/react/actions/toast';
import CardActionHoc, { ICardActionHocProps } from '@temsa/react/hocs/CardActionHoc';
import TranslationHoc, { ITranslationHocProps } from '@temsa/react/hocs/TranslationHoc';
import { replaceAllFavoritesAction, removeFavoriteAction } from '../../actions/favorites';
import { DesktopFavoritePanel, MobileFavoritePanel } from '@temsa/components/FavoritePanels';
import { addItemToComparisionAction, removeComparisionAction } from '../../actions/comparision';
import Favorite from '@temsa/models/Favorite';
import Compare from '@temsa/models/Compare';
import { open, close } from '../../actions/modal';

type IFavoriteList = ICardActionHocProps & IFavoriteProps;

export interface IFavoriteProps extends ITranslationHocProps {
  favorites: IKeyValueObject;
  removeAllFavorites?: (items: IKeyValueObject[]) => any;
  removeFavorite?: (region: string, id: string) => any;
  currentElement: HTMLElement;
}

class FavoriteList extends React.Component<IFavoriteList | IKeyValueObject, any> {
  private compareModel: Compare = new Compare;
  constructor(props: IFavoriteList) {
    super(props);
    this.state = {
      isMounted: false
    };
  }

  public get total(): number {
    return this.favorites.length;
  }

  public get region() {
    return win('_region')+'-'+win('_language');
  }

  public get favorites(): IKeyValueObject[] {
    return get(this.region, this.props.favorites) || [];
  }

  public get isMobile(): boolean {
    return this.props.currentElement.getAttribute('data-device') === 'mobile';
  }

  public get isDesktop(): boolean {
    return this.props.currentElement.getAttribute('data-device') === 'desktop';
  }
  
  public componentDidUpdate() {
    (document.querySelector('#mobile-favorite-count') as HTMLDivElement).innerText = this.total.toString();
  }

  public componentDidMount() {
    win('temsa').listCardShare();

    const desktopCountEl = document.getElementById('desktop-favorite-count') as HTMLDivElement;
    const mobileCountEl  = document.getElementById('mobile-favorite-count');

    if (desktopCountEl && mobileCountEl) {
      mobileCountEl.innerText = desktopCountEl.innerText;
    }
  }
  
  public render() {
    const props = {
      total: this.total,
      trans: this.trans, 
      clear: this.clear, 
      remove: this.remove,
      isMobile: this.isMobile,
      favorites: this.favorites,
      addCompare: this.props.addCompare,
      hasCompare: this.compareModel.has,
    };

    return (
      <>
        {(this.props.favorites  && this.isDesktop) && 
          <DesktopFavoritePanel {...props} />
        }
        {(this.props.favorites  && this.isMobile) && 
          <MobileFavoritePanel {...props} />
        }
      </>
    );
  }

  private remove = (id: string) => {
    this.props.removeFavorite(this.region, id);
    const favorites = [...this.favorites.filter((fav: IKeyValueObject) => fav.id !== id)];
    const newFavorites = {...this.props.favorites, [this.region]: [...favorites]}
    localStorage.setItem('favorites', JSON.stringify(newFavorites));
  };

  private clear = () => {
    localStorage.removeItem('favorites');
    this.props.removeAllFavorites([]);
  };

  private trans = (key: string) => this.props.t(key, Favorite.TRANSLATION_KEY);
}

try {
  const mapStateToProps = (state: IKeyValueObject) => {
    return {
      favorites: state.favorites || {},
      comparisions: state.comparisions || {},
      translations: state.translations.data
    };
  };

  const mapDispatchToProps = (dispatch: CallableFunction) => {
    return {
      toast: (data: IKeyValueObject) => dispatch(showToast(data)),
      modal: (data: IKeyValueObject) => data.open ? dispatch(open(data)) : dispatch(close(data)),
      addItemToCompare: (items: IKeyValueObject[]) => dispatch(addItemToComparisionAction(items)),
      removeItemFromCompare: (region: string, id: string) =>  dispatch(removeComparisionAction(region, id)),
      removeFavorite: (region: string, id: string) => dispatch(removeFavoriteAction(region, id)),
      removeAllFavorites: (items: IKeyValueObject[]) => dispatch(replaceAllFavoritesAction(items)),
    }
  };

  const composedHocs = compose(CardActionHoc, TranslationHoc)(FavoriteList);
  const Component = connect(mapStateToProps, mapDispatchToProps)(composedHocs);
  const userFavLists = Array.prototype.slice.call(document.querySelectorAll('.user-favorite-list'), 0);

  userFavLists.forEach((el: HTMLElement) => {
    Component.defaultProps = {currentElement: el};
    ReactDOM.render(
      <Provider store={container}>
        <Component />
      </Provider>, el
    );
  });
} catch (e) {
  console.error(e);
}