import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect, Provider } from 'react-redux'
import container from '@temsa/reducers';
import { win } from '@temsa/helpers/globals';
import { showToast } from '../actions/toast';
import Compare from '@temsa/models/Compare';
import CardActionHoc from '../hocs/CardActionHoc';
import { parent, get } from '@temsa/helpers/utils';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { ICardActionHocProps } from '../hocs/CardActionHoc';
import TranslationHoc, {ITranslationHocProps} from '@temsa/react/hocs/TranslationHoc';
import { removeComparisionAction, addItemToComparisionAction } from '../actions/comparision';
import { open, close } from '../actions/modal';

type ICompareButton = ICardActionHocProps & ITranslationHocProps & ICompareButtonProps;

export interface ICompareButtonProps {
  addItem: (item: IKeyValueObject) => any;
  removeItem: (region: string, id: string) => any;
  toast: (data: IKeyValueObject) => any;
  comparisions: IKeyValueObject;
}

interface ICompareButtonIcon {
  meta: IKeyValueObject;
  add: (meta: IKeyValueObject) => any;
  has: boolean;
  outline: string;
  strong: string;
  trans: (key: string) => any;
}

const IconButton = ({add, has, outline, strong, trans, meta}: ICompareButtonIcon) => {
  return (
    <button 
      onClick={() => add(meta)} 
      type="button" 
      className={'btn btn-link add-compare-btn compare-switch'+(has ? ' active' : '')}>
      <span 
        title={trans('compare-this-vehicle')} 
        data-toggle="tooltip" 
        data-placement="top">
        <svg className="icon icon-compare" dangerouslySetInnerHTML={{__html: outline}} />
      </span>
      <span 
        title={trans('remove-this-vehicle-from-compare-list')} 
        data-toggle="tooltip" 
        data-placement="top">
        <svg className="icon icon-compare-2" dangerouslySetInnerHTML={{__html: strong}} />
      </span>
    </button>
  )
};

const HtmlButton = ({add, has, outline, strong, trans, meta}: ICompareButtonIcon) => {
  return (
    <button 
      className={'btn btn-outline-dark btn-block btn-sm add-compare-btn compare-switch'+(has ? ' active' : '')}
      onClick={() => add(meta)} 
      type="button" 
      data-toggle="tooltip" 
      data-placement="top">
      <svg className="icon icon-compare" dangerouslySetInnerHTML={{__html: outline}} />
      <svg className="icon icon-compare-2" dangerouslySetInnerHTML={{__html: outline}} />
      {!has ? trans('compare-this-vehicle') : trans('remove-this-vehicle-from-compare-list')}
    </button>
  )
};

const MobileButton = ({add, has, outline, strong, trans, meta}: ICompareButtonIcon) => {
  return (
    <div onClick={() => add(meta)}  className={'btn btn-link' + (has ? ' active' : '')}>
      <svg className="icon icon-compare" dangerouslySetInnerHTML={{__html: outline}} />
      <svg className="icon icon-compare-2" dangerouslySetInnerHTML={{__html: strong}} />
      {!has ? trans('add-to-compare-list-mobile') : trans('remove-compare-mobile')}
    </div>
  )
};

export default class CompareButton extends React.Component<ICompareButton | IKeyValueObject, any> {
  public state = {
    has: false,
    meta: {} as IKeyValueObject
  };

  constructor(props: ICompareButton) {
    super(props);
  }

  public get has(): boolean {
    return this.comparisions.findIndex(f => f.id === this.state.meta.id) > -1;
  }

  public get region() {
    return win('_region')+'-'+win('_language');
  }

  public get comparisions(): IKeyValueObject[] {
    return get(this.region, this.props.comparisions) || [];
  }

  public componentDidMount() {
    const p = parent(this);
    if (!!p) {
      this.setState({
        meta: {
          id: p.getAttribute('data-id'),
          url: p.getAttribute('data-url'),
          title: p.getAttribute('data-title'),
          price: p.getAttribute('data-price'),
          button: p.getAttribute('data-button'),
          short_id: p.getAttribute('data-short-id'),
          image: (p.getAttribute('data-image') || '').replace('cdn/list/', 'cdn/thumb/'),
        }
      });
    }
  }

  render() {
    const star1 = (`<use xlink:href="/assets/images/symbol-defs.svg#icon-compare"></use>`);
    const star2 = (`<use xlink:href="/assets/images/symbol-defs.svg#icon-compare-2"></use>`);
    const props = {
      ...this.state,
      has: this.has,
      strong: star2, 
      outline: star1, 
      trans: this.trans,
      add: this.props.addCompare,
    };

    return (
      <>
        {this.state.meta.button === 'square' && 
          <HtmlButton {...props} />
        }
        {this.state.meta.button === 'mobile' && 
          <MobileButton {...props} />
        }
        {['square', 'mobile'].indexOf(this.state.meta.button) < 0 && 
          <IconButton {...props} />
        }
      </>
    );
  }

  private trans = (key: string) =>  this.props.t(key, Compare.TRANSLATION_KEY);
}

try {
  const mapStateToProps = (state: IKeyValueObject) => {
    return {
      comparisions: state.comparisions || {},
      translations: state.translations.data,
    };
  };

  const mapDispatchToProps = (dispatch: CallableFunction) => {
    return {
      addItemToCompare: (items: IKeyValueObject[]) => dispatch(addItemToComparisionAction(items)),
      removeItemFromCompare: (region: string, id: string) =>  dispatch(removeComparisionAction(region, id)),
      toast: (data: IKeyValueObject) => dispatch(showToast(data)),
      modal: (data: IKeyValueObject) => data.open ? dispatch(open(data)) : dispatch(close(data))
    }
  };

  const composedHocs = compose(TranslationHoc, CardActionHoc)(CompareButton);
  const Component = connect(mapStateToProps, mapDispatchToProps)(composedHocs);
  const actionsButtons = Array.prototype.slice.call(document.querySelectorAll('.comparision-action'), 0);
  
  actionsButtons.forEach((el: HTMLElement) => {
    ReactDOM.render(
      <Provider store={container}>
        <Component />
      </Provider>, el
    );
  });

  // jquery plugins initialize
  win('temsa').secondHandToolTip();
} catch (e) {
  console.error(e);
}

