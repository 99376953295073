import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { FavoriteCard } from './FavoriteCard';

interface IFavoritePanel {
  total: number;
  clear: () => any;
  isMobile: boolean;
  trans: (key: string) => any;
  remove: (id: string) => any;
  favorites: IKeyValueObject[];
  hasCompare: (id: string) => boolean;
  addCompare: (meta: IKeyValueObject) => any;
};

export const DesktopFavoritePanel = ({isMobile, total, trans, clear, remove, favorites, addCompare, hasCompare}: IFavoritePanel) => (
  <>
  <a href="#" className="btn btn-link dropdown-toggle" data-toggle="dropdown">
    {total > 0 && <span id="desktop-favorite-count" className="selected-compare-count">{total}</span>}
    <svg className="icon icon-star" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-star"></use>'}} />
  </a>
  <div className="dropdown-menu dropdown-list-menu dropdown-menu-form dropdown-menu-right" >
    <div className="d-flex justify-content-between mb-2">
      <h6 className="dropdown-title" >{trans('my-favorite-list')}</h6>
      {total > 0 && <a href="#" onClick={clear} className="btn btn-link font-weight-normal" >{trans('delete-all')}</a>}
    </div>
    <div className="dropdown-menu-scroll-view">
      {total < 1 && <div className="card list-second-hand-card-empty">{trans('empty-list')}</div>}
      {favorites.map((fav: IKeyValueObject) => (
        <FavoriteCard 
          fav={fav} 
          key={fav.id} 
          trans={trans}
          remove={remove} 
          showActions={true} 
          isMobile={isMobile}
          addCompare={addCompare} 
          hasCompare={hasCompare(fav.id)}
        />
      ))}
    </div>
  </div>
  </>
);

export const MobileFavoritePanel = ({isMobile, total, trans, clear, remove, favorites, addCompare, hasCompare}: IFavoritePanel) => (
  <>
    {total > 0 && <a href="#" onClick={clear} className="btn btn-link font-weight-normal" >{trans('delete-all')}</a>}
    {favorites.map((fav: IKeyValueObject) => (
      <FavoriteCard 
        fav={fav} 
        key={fav.id} 
        trans={trans}
        remove={remove} 
        showActions={true} 
        isMobile={isMobile}
        addCompare={addCompare} 
        hasCompare={hasCompare(fav.id)}
      />
    ))}
  </>
);