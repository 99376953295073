import { ComponentObject } from '../types/generic';

const react = (name: string|null = null): ComponentObject => {
  return name ? (window as any).__react[name] : (window as any).__react;
}

const store = () => {
  return (window as any).__store;
}

const win = (prop: string): any => {
  return (window as any)[prop];
}

(window as any).react = react;
(window as any).store = store;

export { react, store, win };
