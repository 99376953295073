import { ReducerType, ActionType, StateType } from './index';
import { SHOW_TOAST_MESSAGE } from '../actions/types';
import storage from '../../extensions/storage';

const defaultState = {
  data: {
    show: false,
    message: ''
  }
}

const toastMessage: ReducerType<StateType, any> = (state: StateType = defaultState, action: ActionType) => {
  
  switch(action.type) {
    case SHOW_TOAST_MESSAGE: {
      return {
        ...action.data,
        show: true
      }
    }
    default:
      return state;
  }
}

export default toastMessage;