export default {
  name: 'production',
  api: {
    baseUrl: 'https://temsacertified.com',
  },
  modelsOfRegion: {
    us: '5c6fdc5da1fefd0cd042fa6e',
    eu: '5c6fdbe8a1fefd0cd36b145d',
    tr: '5c6fdb0ba1fefd0cd042fa5a'
  }
};
