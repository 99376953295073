import BaseService from "./baseService";

class CollectionService extends BaseService {
  protected namespace: string = 'lookup';

  public newInstance() {
    return new CollectionService;
  }
}

export default new CollectionService;