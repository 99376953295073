import { OPEN_MODAL, CLOSE_MODAL } from './types';

export interface IModalAction {
  title?: string;
  content?: string;
  open?: boolean;
}

export function open(data: IModalAction) {
  return {
    type: OPEN_MODAL,
    data
  }
}

export function close(data: IModalAction) {
  return {
    type: CLOSE_MODAL,
    data
  }
}