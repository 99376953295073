import { IKeyValueObject } from "@temsa/interfaces/generic";

export default class ArrayCollection {
  protected data: any[] = [];

  constructor(protected args: any[] = []) {
    this.data = [...args];
  }

  public add(item: any): ArrayCollection {
    this.data.push(item);
    return this;
  }

  public get(index: number, defaultValue: any = null): any {
    return this.data[index] || defaultValue;
  }

  public remove(index: number): void {
    delete this.data[index];
    this.data = [...this.data.filter(Boolean)];
  }

  public removeLast() {
    this.data = this.data.slice(0, this.lastIndex());
    return this;
  }

  public lastIndex() {
    return this.data.length - 1;
  }

  public toObject(keys: string[]): IKeyValueObject {
    const payload = {} as IKeyValueObject;
    let i = 0;
    for (const key of keys) {
      payload[key] = this.data[i];
      i++;
    }
    return payload;
  }

  public length(): number {
    return this.data.length;
  }

  public first(): any {
    return this.get(0);
  }

  public last(): any {
    return this.data[this.data.length - 1];
  }

  public all(): any[] {
    return [...this.data];
  }
}
