import * as React from 'react';
import { win } from '@temsa/helpers/globals';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { get } from '@temsa/helpers/utils';
import storage from '@temsa/extensions/storage';
import Favorite from '../models/Favorite';
import Compare from '../models/Compare';

export interface ICardActionHocProps {
  addFav: (meta: IKeyValueObject) => any;
  addCompare: (meta: IKeyValueObject) => any;
  removeCompareItem: (id: string) => any;
  modal: (data: IKeyValueObject) => any;
}

export default function CardActionHoc(WrappedComponent: any) {
  return class extends React.Component<any, any> {
    constructor(props: any) {
      super(props);
      this.state = {};
    }

    public get favorites(): IKeyValueObject[] {
      return get(this.region, this.props.favorites) || [];
    }

    public get comparisions(): IKeyValueObject[] {
      return get(this.region, this.props.comparisions) || [];
    }

    public get region() {
      return win('_region')+'-'+win('_language');
    }

    public addFav = (meta: IKeyValueObject) => {
      const advertisementId: string = meta.id;
      let favorites = [...this.favorites] as IKeyValueObject[];
      const has = !!favorites.find(f => f.id === advertisementId);
      const trans = (key: string) => get(`${Favorite.TRANSLATION_KEY}.${key}`, this.props.translations);

      if (!has) {
        this.props.toast({
          title: trans('favorites-toast-title'),
          msg: trans('advertisement-added-successfuly'),
          onClick: 'goToFavorites' // string function
        });
        favorites.push({...meta});
        this.props.addItemToFavorite({[this.region]: [...favorites]});
      } else {
        this.props.toast({
          title: trans('favorites-toast-title'),
          msg: trans('advertisement-removed-successfuly'),
          onClick: 'goToFavorites' // string function
        });
        favorites = favorites.filter(f => f.id !== advertisementId);
        this.props.removeItemFromFavorite(this.region, advertisementId);
      }
      
      this.setState({has});
      const allFavorites = this.props.favorites;
      storage.setItem('favorites', JSON.stringify({...allFavorites, [this.region]: [...favorites]}));
    };

    public addCompare = (meta: IKeyValueObject) => {
      const advertisementId: string = meta.id;
      let comparisions = [...this.comparisions] as IKeyValueObject[];
      const has = !!comparisions.find(f => f.id === advertisementId);
      const trans = (key: string) => get(`${Compare.TRANSLATION_KEY}.${key}`, this.props.translations);

      if (!has && comparisions.length > 2) {
        this.props.modal({
          open: true, 
          content: trans('compare-limit'),
          title: trans('warning'),
          onClick: 'goToComparison' // string function
        });
        return;
      }
      
      if (!has) {
        this.props.toast({
          title: trans('comparison-toast-title'),
          msg: trans('advertisement-added-successfuly'),
          onClick: 'goToComparison' // string function
        });
        
        comparisions.push({...meta});
        this.props.addItemToCompare({[this.region]: [...comparisions]});
      } else {
        this.props.toast({
          title: trans('comparison-toast-title'),
          msg: trans('advertisement-removed-successfully')
        });
        comparisions = comparisions.filter(f => f.id !== advertisementId);
        this.props.removeItemFromCompare(this.region, advertisementId);
      }
      
      this.setState({has});
      const allFavorites = this.props.comparisions;
      storage.setItem('compares', JSON.stringify({...allFavorites, [this.region]: [...comparisions]}));
    };

    private removeCompareItem = (id: string) => {
      this.props.removeComparision(this.region, id);
      const compares = [...this.comparisions.filter((comp: IKeyValueObject) => comp.id !== id)];
      const newCompares = {
        ...this.props.comparisions, 
        [this.region]: [...compares]
      };
      localStorage.setItem((new Compare).collection, JSON.stringify(newCompares));
    };

    public render() {
      return (
        <WrappedComponent 
          {...this.props} 
          {...this.state} 
          addFav={this.addFav} 
          addCompare={this.addCompare} 
          removeCompareItem={this.removeCompareItem}
        />
      );
    }
  };
}
