import { ReducerType, ActionType, StateType } from './index';
import storage from '@temsa/extensions/storage';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { 
  GET_COMPARISIONS_ACTION, 
  ADD_ITEM_TO_COMPARISION_ACTION, 
  REMOVE_COMPARISION_ACTION, 
  REPLACE_ALL_COMPARISION_ITEMS 
} from '../actions/types';

const defaultState = JSON.parse(storage.getItem('compares') || '{}');

const comparisions: ReducerType<StateType, any> = (state: StateType = defaultState, action: ActionType) => {
  
  switch(action.type) {
    case GET_COMPARISIONS_ACTION: {
      return {...state};
    }
    case ADD_ITEM_TO_COMPARISION_ACTION: {
      return {
        ...state,
        ...action.data
      }
    }
    case REMOVE_COMPARISION_ACTION: {
      return {
        ...state,
        [action.region]: [...((state[action.region] || []).filter((fav: IKeyValueObject) => fav.id !== action.id))]
      }
    }
    case REPLACE_ALL_COMPARISION_ITEMS: {
      return [
        ...action.data
      ]
    }
    default:
      return state;
  }
}

export default comparisions;