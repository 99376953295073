import * as React from 'react';
import { CompareCard } from './CompareCard';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { win } from '@temsa/globals';
import { positiveInt } from '@temsa/helpers/utils';

interface IComparisionPanel {
  total: number;
  clear: () => any;
  remove: (id: string) => any;
  trans: (key: string) => any;
  onCompare: (event: any) => void;
  comparisions: IKeyValueObject[];
  hasFav: (id: string) => boolean;
  addFav: (meta: IKeyValueObject) => any;
};

const allAdvertsEl = document.querySelector('#navigation > a');
export const SvgIcon = ({name}: {name: string}) => <svg className={`icon icon-${name}`} dangerouslySetInnerHTML={{__html: `<use xlink:href="/assets/images/symbol-defs.svg#icon-${name}"></use>`}} />

export const DesktopComparePanel = (props: IComparisionPanel) => (
  <div className="dropdown" id="header-compare-dropdown">
    <a href="#" className="btn btn-link dropdown-toggle" data-toggle="dropdown">
      {props.total > 0 && <span id="desktop-compare-count" className="selected-compare-count">{props.total}</span>}
      <SvgIcon name="compare" />
    </a>
    <div className="dropdown-menu dropdown-menu-form dropdown-list-menu dropdown-menu-right" >
      <div className="d-flex justify-content-between mb-2">
        <h6 className="dropdown-title">{props.trans('my-comparison-list')}</h6>
        {props.total > 0 && 
          <a 
            href="#" 
            onClick={props.clear} 
            className="btn btn-link font-weight-normal">{props.trans('delete-all')}
          </a>
        }
      </div>
      <div className="dropdown-menu-scroll-view">
        {props.comparisions.map(comp => (
          <CompareCard 
            comp={comp} 
            {...props}
            key={comp.id} 
            showActions={true} 
            hasFav={props.hasFav(comp.id)}
          />
        ))}
        {Array(positiveInt(3 - props.comparisions.length)).fill(1).map((v, i) => (
          <div key={i} className="card second-hand-empty-card" />
        ))}
      </div>
      {(!!allAdvertsEl) &&
        <button 
          className="btn btn-dark btn-block mt-3"
          onClick={(e) => props.comparisions.length < 2 ? win('location').href = allAdvertsEl.getAttribute('href') : props.onCompare({...e})}>
          {props.comparisions.length < 2 ? props.trans('add-advert') : props.trans('compare')}
        </button>
      }
    </div>
  </div>
);

export const MobileComparePanel = (props: IComparisionPanel) => (
  <>
    {props.total > 0 && <a href="#" onClick={props.clear} className="btn btn-link font-weight-normal" >{props.trans('delete-all')}</a>}
    {props.comparisions.map((comp: IKeyValueObject) => (
      <CompareCard 
        comp={comp} 
        {...props}
        key={comp.id} 
        showActions={false} 
        hasFav={props.hasFav(comp.id)} 
      />
    ))}
    {Array(positiveInt(3 - props.comparisions.length)).fill(1).map((v, i) => (
      <div key={i} className="card second-hand-empty-card" />
    ))}
    {(!!allAdvertsEl) &&
      <a 
        href="javascript:;" 
        className="btn btn-dark position-absolute" id="mobile-menu-compare-button"
        onClick={(e) => props.comparisions.length < 2 ? win('location').href = allAdvertsEl.getAttribute('href') : props.onCompare({...e})}>
        {props.comparisions.length < 2 ? props.trans('add-advert') : props.trans('compare')}
      </a>
    }
  </>
);