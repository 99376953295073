export const ADD_TODO = 'ADD_TODO';

export const GET_FAVORITES_ACTION = 'GET FAVORITES';
export const ADD_ITEM_TO_FAVORITES_ACTION = 'ADD ITEM TO FAVORITES ACTION';
export const REMOVE_FAVORITE_ACTION = 'REMOVE FAVORITE ACTION';
export const REPLACE_ALL_FAVORITE_ITEMS = 'REPLACE ALL FAVORITE ITEMS ACTION';

export const SHOW_TOAST_MESSAGE = 'SHOW TOAST MESSAGE ACTION';

export const ADD_QUERY_PARAMETERS_ACTION = 'ADD QUERY PARAMETERS ACTION';
export const REMOVE_QUERY_PARAMETERS_ACTION = 'REMOVE QUERY PARAMETERS ACTION';
export const REPLACE_ALL_QUERY_PARAMETERS_ACTION = 'REPLACE ALL QUERY PARAMETERS ACTION';
export const ADD_TAG_PARAMETERS_ACTION = 'ADD TAG PARAMETERS ACTION';
export const REMOVE_TAG_PARAMETERS_ACTION = 'REMOVE TAG PARAMETERS ACTION';
export const REPLACE_ALL_TAG_PARAMETERS_ACTION = 'REPLACE ALL TAG PARAMETERS ACTION';

export const ADD_SIDEBAR_PARAMETERS_ACTION = 'ADD SIDEBAR PARAMETERS ACTION';

export const ADD_FILTER_TRANSLATIONS_ACTION = 'ADD FILTER TRANSLATIONS ACTION';

export const GET_COMPARISIONS_ACTION = 'GET COMPARISIONS';
export const ADD_ITEM_TO_COMPARISION_ACTION = 'ADD ITEM TO COMPARISION ACTION';
export const REPLACE_ALL_COMPARISION_ITEMS = 'REPLACE ALL COMPARISION ITEMS';
export const REMOVE_COMPARISION_ACTION = 'REMOVE COMPARISION ACTION';

export const ADD_TRANSLATION_PACKAGE_ACTION = 'ADD TRANSLATION PACKAGE ACTION';

export const OPEN_MODAL = 'OPEN MODAL ACTION';
export const CLOSE_MODAL = 'CLOSE MODAL ACTION';