import { ADD_FILTER_TRANSLATIONS_ACTION, ADD_TRANSLATION_PACKAGE_ACTION } from './types';
import { IKeyValueObject, IAction } from "@temsa/interfaces/generic";

export interface IAddTranslationsAction extends IAction {
  data: IKeyValueObject;
}

export function addFilterTranslationsAction(data: IKeyValueObject): IAddTranslationsAction {
  return {
    type: ADD_FILTER_TRANSLATIONS_ACTION,
    data
  }
}

export function addTranslationPackageAction(data: IKeyValueObject): IAddTranslationsAction {
  return {
    type: ADD_TRANSLATION_PACKAGE_ACTION,
    data
  }
}