import * as ReactDOM from 'react-dom';
import * as unorm from 'unorm';
import configData from '../config';
import { win } from './globals';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import ArrayCollection from '@temsa/extensions/ArrayCollection';

export function flat(data: any[]): any[] {
  return data.reduce((acc, val) => acc.concat(val), []);
}

export function except(data: IKeyValueObject, keys: string[]) {
  const newData = {...data};
  Object.keys(newData).forEach(key => {
    if (keys.indexOf(key) > -1) {
      delete newData[key];
    }
  });
  return {...newData};
}

export function only(data: any, keys: Array<any>) {
  return keys.reduce((result, key) => {
    if (data.hasOwnProperty(key)) {
      result[key] = data[key];
    }
    return result;
  }, {});
}

export function has(path: string, data: object): boolean {
  return !!get(path, data)
}

export function get(path: string = '', data: object, defaultData?: any): any {
  return path.split('.').reduce((result: IKeyValueObject, key: string) => Boolean(result) && Boolean(result[key]) ? result[key] : defaultData, {...data});
}

export function isMongoId(id: string): boolean {
  return /^[0-9a-fA-F]{24}$/.test(id);
}

export function parent(component: React.Component): Element|IKeyValueObject|null {
  const node = (ReactDOM.findDOMNode(component) as Element);
  return node ? (ReactDOM.findDOMNode(component) as Element).parentElement || {} : null;
}

export function runIf(condition: boolean, tasks: Function[], elseTasks: Function[] = []) {
  if (condition) {
    tasks.map(task => task());
  }
  else if(Array.isArray(elseTasks)) {
    elseTasks.map(task => task());
  }
}

export function location() {
  return win('_region')+'-'+win('_language');
}

export function config(path: string): any {
  return get(path, configData);
}

export function trans(path: string): string {
  return win('_lookupTable')[get(path, configData.translations)];
}

export function lookup(path: string): any {
  return get(path, win('_lookupTable'));
}

export function escape(value: string, piece: string = '-'): string {
  // value.normalize('NFD')
  return unorm.nfd(value)
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ı/g, 'i')
    .replace(/[^a-zA-Z0-9\_\-\s]/ig, '')
    .replace(/\s+/g, ' ')
    .replace(/\s/g, piece)
}

export function kebabCase(value: string) {
  return escape(value, '-').toLocaleLowerCase();
}

export function objectArrayDiff(original: IKeyValueObject[] = [], external: IKeyValueObject[] = [], key: string) {
  const result = {
  	diff: [] as IKeyValueObject[],
    common: [] as IKeyValueObject[],
  };

  const externalClone = external.slice(0);

  original.forEach(o => {
    const a = external.findIndex(e => e[key] === o[key]);
    if (a > -1) {
      result.common.push(external[a]);
      delete externalClone[a]
    }
  });

  result.diff = [...externalClone.filter(w => w)];

  return result;
}

export function objectEntries(obj: IKeyValueObject): any[][] {
  let ownProps = Object.keys( obj ),
    i = ownProps.length,
    resArray = new Array(i); // preallocate the Array
  while (i--)
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  
  return resArray;
}

export function cast(data: IKeyValueObject, casts: IKeyValueObject): IKeyValueObject {
  const newData = Object.keys(casts).reduce((result: IKeyValueObject, key) => {
    const value = Array.isArray(data[key]) ? data[key][0] : data[key];
    
    if (value && casts[key] === 'string') {
      result[key] = value;
    }
    else if(value && casts[key] === 'number') {
      result[key] = parseInt(value, 10);
    }
    return result;
  }, {});
  
  return {...data, ...newData};
};

export function collect(data: any[] = []): ArrayCollection {
  return new ArrayCollection(data);
}

export function sanitizeNumber(val: any = ''): number {
  val = typeof(val) !== 'string' ? val.toString() : val;
  return parseInt(val.replace(/[^\d]+/g, ''));
}

export function positiveInt(val: number): number {
  return val < 0 ? 0 : val;
}