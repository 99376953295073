import { ReducerType, ActionType, StateType } from './index';
import { ADD_FILTER_TRANSLATIONS_ACTION, ADD_TRANSLATION_PACKAGE_ACTION } from '../actions/types';

const defaultFilterState = {
  data: {}
}

const filterTranslations: ReducerType<StateType, any> = (state: StateType = defaultFilterState, action: ActionType) => {
  
  switch(action.type) {
    case ADD_FILTER_TRANSLATIONS_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        }
      }
    }
    default:
      return state;
  }
}

const translations: ReducerType<StateType, any> = (state: StateType = defaultFilterState, action: ActionType) => {
  switch(action.type) {
    case ADD_TRANSLATION_PACKAGE_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        }
      }
    }
    default:
      return state;
  }
}

export { filterTranslations, translations };