import { ADD_ITEM_TO_COMPARISION_ACTION, REPLACE_ALL_COMPARISION_ITEMS, REMOVE_COMPARISION_ACTION } from './types';

export interface IAddItemAction {
  [prop: string]: boolean;
}

export function addItemToComparisionAction(data: IAddItemAction[]) {
  return {
    type: ADD_ITEM_TO_COMPARISION_ACTION,
    data
  }
}
export function replaceAllComparisionAction(data: IAddItemAction[]) {
  return {
    type: REPLACE_ALL_COMPARISION_ITEMS,
    data
  }
}
export function removeComparisionAction(region: string, id: string) {
  return {
    type: REMOVE_COMPARISION_ACTION,
    region,
    id
  }
}