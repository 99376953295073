import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { config } from '@temsa/helpers/utils';

export const share = (data: IKeyValueObject) => {
  const params = new URLSearchParams({
    original_referer: location.href,
    via: config('social.twitter.username'),
    ...data,
  });

  const shareUrl = config(`social.${data.platform}.share_url`);
  window.open(shareUrl+'?'+params.toString(), 'newwindow');
}

export default ({item}: {item: IKeyValueObject}) => (
  <div className="share-widget">
    <a href={`mailto:?subject=${item.title}&body=${item.url}`}>
      <svg className="icon icon-envelope" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-envelope"></use>'}} />
    </a>
    <a href="#" onClick={() => share({text: item.url, platform: 'whatsapp'})}>
      <svg className="icon icon-whatsapp" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-whatsapp"></use>'}} />
    </a>
    <a href="#" onClick={() => share({text: item.title, url: item.url, platform: 'twitter'})}>
      <svg className="icon icon-twitter" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-twitter"></use>'}} />
    </a>
    <a href="#" onClick={() => share({text: item.title, u: item.url, platform: 'facebook'})}>
      <svg className="icon icon-facebook" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-facebook"></use>'}} />
    </a>
  </div>
);