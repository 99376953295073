import * as React from 'react';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import ShareWidget from '@temsa/components/header/ShareWidget';

interface IFavoriteCardProps {
  hasCompare: boolean;
  showActions: boolean;
  isMobile: boolean;
  fav: IKeyValueObject;
  remove: (id: string) => any;
  trans: (key: string) => string;
  addCompare: (meta: IKeyValueObject) => any
};

const replacePrince = (price: any) => {
  const numberPattern = /\d+/g;
  const newPrice = price.replace(/\./g, '')
    .replace(/,/g, '')
    .replace(/\s+/g, '')
    .match( numberPattern );
  if (newPrice > 0) {
    return price;
  }
  return ' ';
};

export const FavoriteCard = ({ isMobile, fav, showActions = true, remove, addCompare, hasCompare, trans }: IFavoriteCardProps) => (
  <div className="card list-second-hand-card">
    <img onClick={() => location.href = fav.url} src={fav.image} alt="" style={{ cursor: 'pointer', height: '68px', width: 'initial' }} />
    <div className="card-body" style={{ height: '68px' }}>
      <div className="list-second-share-box">
        <a href="#" className="list-second-share-box-close">
          <svg className="icon icon-times" dangerouslySetInnerHTML={{ __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-times"></use>' }} />
        </a>
        <ShareWidget item={fav} />
      </div>
      <h6 style={{ cursor: 'pointer' }} onClick={() => location.href = fav.url}>
        {fav.title}
        <br />
        {replacePrince(fav.price)}
      </h6>
      {showActions &&
        <div className="list-card-actions" >
          <a
            href="javascript:;"
            style={{ height: 'auto' }}
            onClick={() => addCompare(fav)}
            className={'header-fav-compare-icon pl-0' + (hasCompare ? ' active' : '')}>
            <span style={{ fontSize: '15px' }} data-toggle="tooltip" data-placement="top" title={trans('add-to-compare-list')}>
              <svg className="icon icon-compare" dangerouslySetInnerHTML={{ __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-compare"></use>' }} />
            </span>
            <span style={{ fontSize: '15px' }} data-toggle="tooltip" data-placement="top" title={trans('remove-from-compare-list')}>
              <svg className="icon icon-compare-2" dangerouslySetInnerHTML={{ __html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-compare-2"></use>' }} />
            </span>
          </a>
          {/* {isMobile !== true &&
            <a href="javascript:;" data-toggle="tooltip" data-placement="top" title={trans('favorite-share-title')} className="list-card-share-btn" >
              <svg className="icon icon-share" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-share"></use>'}} />
            </a>
          } */}
        </div>
      }
      <button type="button" onClick={() => remove(fav.id)} className="remove-item force-propagation">
        <svg className="icon icon-times" dangerouslySetInnerHTML={{ __html: '<use id="remove" xlink:href="/assets/images/symbol-defs.svg#icon-times"></use>' }} />
      </button>
    </div>
  </div>
);