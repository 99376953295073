import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import container from './../reducers';
import { addComponent } from '../../helpers/redux';
import { IKeyValueObject } from '../../interfaces/generic';
import * as staticFunctions from '@temsa/helpers/string-functions';
import { has, get } from '@temsa/helpers/utils';


class Toast extends React.Component<any, any> {
  public state = {
    timeout: 3000,
    messages: []
  };

  public get messages(): IKeyValueObject[] {
    return this.state.messages.filter((msg: IKeyValueObject) => msg.show === true);
  }

  public get onClick(): any {
    return has(this.props.toast.onClick, staticFunctions) ?
      eval(get(this.props.toast.onClick, staticFunctions)) : 
      () => null
  }

  public componentWillReceiveProps(prevProps: IKeyValueObject, prevState: IKeyValueObject) {
    const messages = [...this.state.messages, prevProps.toast];
    this.setState({messages});
    
    const lastIndex = messages.length - 1;
    setTimeout(() => this.hide(lastIndex), this.state.timeout);
  }

  public render() {
    return (
      <>
      {this.messages.length > 0 &&
        <>
          {/* desktop toast html node */}
          <div aria-live="polite" aria-atomic="true" className="toast-wrapper d-lg-block d-none">
            {/* Position it */}
            <div style={{position: 'absolute', top: 0, right: 0}}>
              {this.messages.map((toast, index) => {
                return (
                  <div key={index} className={"toast fade" + (toast.show ? ' show' : '')} role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-header">
                        <strong className="mr-auto">{toast.title}</strong>
                        <button type="button" onClick={() => this.hide(index)} className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="toast-body">
                      {toast.msg}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* mobile toast html node */}
          <div aria-live="polite" aria-atomic="true" className="toast-wrapper-mobile d-lg-none d-block">
            <div style={{position: 'absolute', top: 0, right: 0, left: 0}}>
              {this.messages.map((toast, index) => (
                <div onClick={this.onClick} key={index} className={'toast fade' + (toast.show ? ' show' : '')} role="alert" aria-live="assertive" aria-atomic="true">
                  <div className="toast-body justify-content-center align-items-center">
                    {toast.msg}
                    <button type="button" onClick={() => this.hide(index)} className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                      <span aria-hidden="true">
                        <svg className="icon icon-times" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-times"></use>'}} />
                      </span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      }
      </>
    );
  }
  
  private hide = (index: number) => {
    const messages = [...this.state.messages] as IKeyValueObject;
    delete messages[this.state.messages.length - 1];
    this.setState({messages: messages.filter(Boolean)});
  }
}

try {
  const mapStateToProps = (state: IKeyValueObject) => {
    return {
      toast: state.toastMessage
    };
  };

  const Component = connect(mapStateToProps, null)(Toast);

  const toast = ReactDOM.render(
    <Provider store={container}>
      <Component />
    </Provider>, document.getElementById('toaster')
  );

  addComponent({ toast });
} catch (e) {
  console.error(e);
}