import Model from "./Model";
import { win } from "@temsa/helpers/globals";
import { IKeyValueObject } from "@temsa/interfaces/generic";

export default class Favorite extends Model {
  public collection: string = 'favorites';
  static readonly TRANSLATION_KEY: string = win('_region')+'-'+win('_language')+'-favorites';

  constructor(attr: IKeyValueObject = {}) {
    super(attr);
  }
}