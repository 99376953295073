import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux'
import { IKeyValueObject } from '@temsa/interfaces/generic';
import container from './../../reducers';
import translations from '@temsa/translations';
import Favorite from '@temsa/models/Favorite';
import Compare from '@temsa/models/Compare';
import TranslationHoc, { ITranslationHocProps } from '@temsa/react/hocs/TranslationHoc';
import Modal from '@temsa/components/Modal';

export interface IFavoriteProps extends ITranslationHocProps {
  favorites: IKeyValueObject;
  removeAllFavorites?: (items: IKeyValueObject[]) => any;
  removeFavorite?: (region: string, id: string) => any;
  currentElement: HTMLElement;
}

class Initializer extends React.Component<IFavoriteProps | IKeyValueObject, any> {
  constructor(props: IFavoriteProps) {
    super(props);
  }

  public componentDidMount() {
    this.props.getTranslations(translations.favorites, Favorite.TRANSLATION_KEY);
    this.props.getTranslations(translations.comparision, Compare.TRANSLATION_KEY);
  }
  
  public render() {
    return (
      <Modal />
    );
  }
}

try {
  const mapStateToProps = (state: IKeyValueObject) => {
    return {
      favorites: state.favorites || {}
    };
  };

  const el = document.getElementById('initializer-component');
  const Component = connect(mapStateToProps, null)(TranslationHoc(Initializer));
  
  Component.defaultProps = {currentElement: el};

  ReactDOM.render(
    <Provider store={container}>
      <Component />
    </Provider>, el
  );
} catch (e) {
  console.error(e);
}