import { combineReducers, createStore } from 'redux';
import { initializeStore } from '../../helpers/redux';
import favorites from './favorites';
import { store } from '../../helpers/globals';
import toastMessage from './toast';
import { filterParameters, tagParameters, sidebarParameters } from './filter';
import { filterTranslations, translations } from './translations';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import comparisions from './comparision';
import modal from './modal';


export type StateType = IKeyValueObject;
export type ReducerType<S, A> = (state: S, action: A) => S;
export type ActionType = {type: string, data: any, [prop: string]: any};
export type AsyncActionType = {type: string, data: any};
export type ActionCreator = (...args: any) => ActionType | AsyncActionType

const reducers = combineReducers({
  favorites,
  comparisions,
  toastMessage,
  filterParameters,
  tagParameters,
  sidebarParameters,
  filterTranslations,
  translations,
  modal
});

initializeStore(reducers);

export default store();
