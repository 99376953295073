import axios, { AxiosRequestConfig } from 'axios';
import { 
  IResourceServiceParameters, 
  IServiceResources, 
  IServiceTransformer, 
  IKeyValueObject 
} from '@temsa/interfaces/generic';

export default class BaseService implements IServiceResources, IServiceTransformer {
  protected collectionId: string;
  protected namespace: string;

  public purge(params: IKeyValueObject) {
    delete params.segments;
    return params;
  }

  public index(params: IResourceServiceParameters = {}): Promise<any> {
    const segments = this.prepareSegments(params.additionalUri);
    return axios.get(`/${this.namespace}${segments}`, {params: params.query} as AxiosRequestConfig);
  }

  public show(id: string, params: IResourceServiceParameters = {}): Promise<any> {
    const segments = this.prepareSegments(params.additionalUri);
    return axios.get(`/${this.namespace}/${id}${segments}`, params as AxiosRequestConfig);
  }

  public create(data: IKeyValueObject, params: IResourceServiceParameters = {}): Promise<any> {
    const segments = this.prepareSegments(params.additionalUri);
    return axios.post(`${this.namespace}${segments}`, data);
  }

  public update(id: string, data: IKeyValueObject, params: IResourceServiceParameters = {}): Promise<any> {
    const segments = this.prepareSegments(params.additionalUri);
    return axios.put(`/${this.namespace}/${id}${segments}`, data);
  }

  public patch(id: string, data: IKeyValueObject, params: IResourceServiceParameters = {}): Promise<any> {
    const segments = this.prepareSegments(params.additionalUri);
    return axios.patch(`/${this.namespace}/${id}${segments}`, data);
  }

  public delete(id: string, params: IResourceServiceParameters = {}): Promise<any> {
    const segments = this.prepareSegments(params.additionalUri);
    return axios.delete(`/${this.namespace}/${id}${segments}`);
  }

  public setNamespace(namespace: string = ''): void {
    this.namespace = namespace;
  }

  public getNamespace(): string {
    return this.namespace;
  }

  private prepareSegments(uri: any[] = []): string {
    const segments = uri.length > 0 ? ['', ...uri] : [];
    return segments.join('/');
  }
}
