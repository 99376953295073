import { createStore } from "redux";
import { store, react } from "./globals";
import { KeyValue } from '../types/generic';

export function enableDevToolIfExists() {
  return (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__();
}

export function initializeStore(reducers: any): void {
  if (! (window as any).__store) {
    (window as any).__store = createStore(reducers, enableDevToolIfExists());
  }
}

export const connect = (component: any, ext: KeyValue = {}, provider: Function) => {
  const { dispatch } = store();
  component.memoizedProps = {...provider(store().getState()), ...ext, dispatch };
  return component;
};

export const addComponent = (component: any): void => {
  (window as any).__react = { ...react(), ...component };
};