import production from './../environments/production';
import development from './../environments/development';
import stage from './../environments/stage';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import { get, config } from '@temsa/utils';

class EnvService {
  private environment: IKeyValueObject;

  constructor() {
    const url = location.href;
    const isDev = config('states.dev').find((e: string) => url.indexOf(e) > -1);
    const isProd = config('states.prod').find((e: string) => url.indexOf(e) > -1);
    const isStage = config('states.stage').find((e: string) => url.indexOf(e) > -1);

    if (!!isDev === true) {
      this.environment = {...development};
    }
    else if(!!isStage === true) {
      this.environment = stage;
    } 
    else {
      this.environment = production;
    }
  }

  public get(path: string): any {
    return get(path, this.environment);
  }
}

export default new EnvService;