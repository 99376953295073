import * as React from 'react';
import { SvgIcon } from "./ComparisionPanels";
import { IKeyValueObject } from '@temsa/interfaces/generic';
import ShareWidget from '@temsa/components/header/ShareWidget';

interface ICompareCardProps {
  comp: IKeyValueObject;
  showActions: boolean;
  remove: (id: string) => any;
  addFav: (meta: IKeyValueObject) => any;
  hasFav: boolean;
  trans: (key: string) => string;
}

const replacePrince = (price: any) => {
  const numberPattern = /\d+/g;
  const newPrice = price.replace(/\./g, '')
    .replace(/,/g, '')
    .replace(/\s+/g, '')
    .match( numberPattern );
  if (newPrice > 0) {
    return price;
  }
  return ' ';
};

export const CompareCard = ({ comp, showActions = true, remove, addFav, hasFav, trans }: ICompareCardProps) => (
  <div className="card list-second-hand-card">
    {console.log(comp)}
    <img onClick={() => location.href = comp.url} src={comp.image} alt="" style={{ cursor: 'pointer', height: '68px', width: 'initial' }} />
    <div className="card-body">
      {showActions &&
        <div className="list-second-share-box">
          <a href="#" className="list-second-share-box-close">
            <SvgIcon name="times" />
          </a>
          <ShareWidget item={comp} />
        </div>
      }
      <h6>{comp.title}<br />{replacePrince(comp.price)}</h6>
      <div className="list-card-actions">
        <a
          href="#"
          style={{ height: '100%' }}
          onClick={() => addFav(comp)}
          className={'header-compare-fav-icon pl-0' + (hasFav ? ' active' : '')}>
          <span style={{ fontSize: '15px' }} title="" data-placement="top" data-toggle="tooltip">
            <SvgIcon name="star" />
          </span>
          <span style={{ fontSize: '15px' }} title="" data-placement="top" data-toggle="tooltip">
            <SvgIcon name="star-2" />
          </span>
        </a>
      </div>
      <button
        type="button"
        data-placement="top"
        data-toggle="tooltip"
        className="remove-item"
        onClick={() => remove(comp.id)}>
        <SvgIcon name="times" />
      </button>
    </div>
  </div>
);