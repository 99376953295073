import Model from "./Model";
import { win } from "@temsa/helpers/globals";
import { IKeyValueObject } from "@temsa/interfaces/generic";

export default class Compare extends Model {

  constructor(attr: IKeyValueObject = {}) {
    super(attr);
  }

  public collection: string = 'compares';
  static readonly TRANSLATION_KEY: string = win('_region')+'-'+win('_language')+'-compares';
}