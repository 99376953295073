import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect, Provider } from 'react-redux'
import { stringify } from 'query-string';
import { IKeyValueObject, IPersistItem } from '@temsa/interfaces/generic';
import { get, location as fullRegion } from '@temsa/utils';
import container from './../../reducers';
import Compare from '@temsa/models/Compare';
import { win } from '@temsa/helpers/globals';
import Favorite from '@temsa/react/models/Favorite';
import { showToast } from '@temsa/react/actions/toast';
import { open, close } from '@temsa/react/actions/modal';
import { DesktopComparePanel, MobileComparePanel } from '../ComparisionPanels';
import CardActionHoc, { ICardActionHocProps } from '@temsa/react/hocs/CardActionHoc';
import TranslationHoc, { ITranslationHocProps } from '@temsa/react/hocs/TranslationHoc';
import { addItemToFavoritesAction, removeFavoriteAction } from '@temsa/react/actions/favorites';
import { removeComparisionAction, replaceAllComparisionAction } from '@temsa/react/actions/comparision';

type IComparisionList = ICardActionHocProps & ITranslationHocProps & IComparisionProps;

export interface IComparisionProps {
  currentElement: HTMLElement;
  translations: IKeyValueObject;
  comparisions: IKeyValueObject;
  removeComparision: (region: string, id: string) => any;
  removeAllComparision: (items: IKeyValueObject[]) => any;
}

class ComparisionList extends React.Component<IComparisionList, any> {
  private favoriteModel: Favorite = new Favorite;
  private compareModel: Compare = new Compare;
  constructor(props: IComparisionList) {
    super(props);
    this.state = {
      isMounted: false
    };
  }

  public get total(): number {
    return this.comparisions.length;
  }

  public get region(): string {
    return fullRegion();
  }

  public get collection(): string {
    return this.compareModel.collection
  }

  public get comparisions(): IKeyValueObject[] {
    return get(this.region, this.props.comparisions) || [];
  }

  public get isMobile(): boolean {
    return this.props.currentElement.getAttribute('data-device') === 'mobile';
  }

  public get isDesktop(): boolean {
    return this.props.currentElement.getAttribute('data-device') === 'desktop';
  }

  public componentDidUpdate() {
    (document.querySelector('#mobile-compare-count') as HTMLDivElement).innerText = this.total.toString();
  }

  public componentDidMount() {
    win('temsa').listCardShare();

    const desktopCountEl = document.getElementById('desktop-compare-count');
    const mobileCountEl  = document.getElementById('mobile-compare-count');

    if (desktopCountEl && mobileCountEl) {
      mobileCountEl.innerText = desktopCountEl.innerText;
    }
  }
  
  public render() {
    const props = {
      total: this.total,
      clear: this.clear, 
      trans: this.trans,
      onCompare: this.go,
      addFav: this.props.addFav,
      hasFav: this.favoriteModel.has,
      comparisions: this.comparisions,
      remove: this.props.removeCompareItem,
    };
    
    return !this.props.translations ? null : (
      <>
        {(this.props.comparisions && this.isDesktop) && 
          <DesktopComparePanel {...props} />
        }
        {(this.props.comparisions && this.isMobile) && 
          <MobileComparePanel {...props} />
        }
      </>
    );
  }

  private go = (event: MouseEvent) => {
    const compares = get(this.region, this.props.comparisions) as IPersistItem[];
    const ids = compares.map(comp => comp.short_id).join(',');
    location.href = `/${this.region}/compare?${stringify({ ids })}`;
  }

  private clear = () => {
    localStorage.removeItem(this.collection);
    this.props.removeAllComparision([]);
  };

  private trans = (key: string): string => this.props.t(key, Compare.TRANSLATION_KEY);
}

try {
  const mapStateToProps = (state: IKeyValueObject) => {
    return {
      favorites: state.favorites || {},
      comparisions: state.comparisions || {},
      translations: state.translations.data
    };
  };

  const mapDispatchToProps = (dispatch: CallableFunction) => {
    return {
      toast: (data: IKeyValueObject) => dispatch(showToast(data)),
      addItemToFavorite: (items: IKeyValueObject[]) => dispatch(addItemToFavoritesAction(items)),
      removeItemFromFavorite: (region: string, id: string) =>  dispatch(removeFavoriteAction(region, id)),
      removeComparision: (region: string, id: string) => dispatch(removeComparisionAction(region, id)),
      removeAllComparision: (items: IKeyValueObject[]) => dispatch(replaceAllComparisionAction(items)),
      modal: (data: IKeyValueObject) => data.open ? dispatch(open(data)) : dispatch(close(data))
    }
  };

  const composedHocs = compose(CardActionHoc, TranslationHoc)(ComparisionList);
  const Component = connect(mapStateToProps, mapDispatchToProps)(composedHocs);
  const userCompLists = Array.prototype.slice.call(document.querySelectorAll('.user-comparision-list'), 0);

  userCompLists.forEach((el: HTMLElement) => {
    Component.defaultProps = {currentElement: el};
    ReactDOM.render(
      <Provider store={container}>
        <Component />
      </Provider>, el
    );
  });
} catch (e) {
  console.error(e);
}