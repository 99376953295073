import { SHOW_TOAST_MESSAGE } from './types';

export interface IToastAction {
  [prop: string]: boolean;
}

export function showToast(data: IToastAction) {
  return {
    type: SHOW_TOAST_MESSAGE,
    data
  }
}