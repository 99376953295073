class Storage implements WindowLocalStorage {
  readonly localStorage: Storage;

  public length: number = localStorage.length;

  public setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  public getItem(key: string): any {
    return localStorage.getItem(key);
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }

  public key(index: number): string|null {
    return localStorage.key(index);
  }
}

export default new Storage;