import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect, Provider } from 'react-redux'
import container from './../reducers';
import { win } from '../../helpers/globals';
import { showToast } from '../actions/toast';
import { parent, get } from '../../helpers/utils';
import CardActionHoc from '../hocs/CardActionHoc';
import { IKeyValueObject } from '../../interfaces/generic';
import { ICardActionHocProps } from '../hocs/CardActionHoc';
import { addItemToFavoritesAction, removeFavoriteAction } from '../actions/favorites';
import TranslationHoc from '@temsa/react/hocs/TranslationHoc';
import { ITranslationHocProps } from '@temsa/react/hocs/TranslationHoc';
import Favorite from '../models/Favorite';

type IFavoriteButton = ICardActionHocProps & IFavoriteButtonProps & ITranslationHocProps;

export interface IFavoriteButtonProps {
  addItem: (item: IKeyValueObject) => any;
  removeItem: (region: string, id: string) => any;
  toast: (data: IKeyValueObject) => any;
  addTranslationPackage: (transltion: IKeyValueObject) => any;
  favorites: IKeyValueObject;
  translations: IKeyValueObject;
}

interface IFavoriteButtonIcon {
  meta: IKeyValueObject;
  trans: (key: string) => string;
  add: (meta: IKeyValueObject) => any;
  has: boolean;
  star1: string;
  star2: string;
}

const StarButton = ({add, has, star1, star2, meta, trans}: IFavoriteButtonIcon) => (
  <button 
    onClick={() => add(meta)} 
    type="button" 
    className={'btn btn-link add-favorite-btn'+(has ? ' active' : '')}>
    <span 
      title={trans('add-to-favorites')} 
      data-toggle="tooltip" 
      data-placement="top">
      <svg 
        className="icon icon-star" 
        dangerouslySetInnerHTML={{__html: star1}} 
      />
    </span>
    <span 
      title={trans('remove-from-favorites')} 
      data-toggle="tooltip" 
      data-placement="top">
      <svg 
        data-toggle="tooltip" 
        data-placement="top" 
        className="icon icon-star-2" 
        dangerouslySetInnerHTML={{__html: star2}} 
        />
    </span>
  </button>
);

const SquareButton = ({add, has, star1, star2, meta, trans}: IFavoriteButtonIcon) => (
  <button 
    className={'btn btn-outline-dark btn-block btn-sm add-favorite-btn'+(has ? ' active' : '')}
    onClick={() => add(meta)} 
    type="button" 
    data-toggle="tooltip" 
    data-placement="top">
    <svg className="icon icon-star" dangerouslySetInnerHTML={{__html: star1}} />
    <svg className="icon icon-star-2" dangerouslySetInnerHTML={{__html: star2}} />
    {!has ? trans('add-to-favorites') : trans('remove-from-favorites')}
  </button>
);

const MobileButton = ({add, has, star1, star2, meta, trans}: IFavoriteButtonIcon) => (
  <button className={'btn btn-link'+(has ? ' active' : '')} onClick={() => add(meta)}>
    <svg className="icon icon-star" dangerouslySetInnerHTML={{__html: star1}} />
    <svg className="icon icon-star-2" dangerouslySetInnerHTML={{__html: star2}} />
    {!has ? trans('add-to-favorites-mobile') : trans('remove-from-favorites-mobile')}
  </button>
);

export default class FavoriteButton extends React.Component<IFavoriteButton, any> {
  public state = {
    has: false,
    meta: {} as IKeyValueObject
  };

  constructor(props: IFavoriteButton) {
    super(props);
  }

  public get has(): boolean {
    return this.favorites.findIndex(f => f.id === this.state.meta.id) > -1;
  }

  public get region() {
    return win('_region')+'-'+win('_language');
  }

  public get favorites(): IKeyValueObject[] {
    return get(this.region, this.props.favorites) || [];
  }

  public componentDidMount() {
    const p = parent(this);
    if (!!p) {
      this.setState({
        meta: {
          id: p.getAttribute('data-id'),
          url: p.getAttribute('data-url'),
          title: p.getAttribute('data-title'),
          price: p.getAttribute('data-price'),
          button: p.getAttribute('data-button'),
          short_id: p.getAttribute('data-short-id'),
          image: (p.getAttribute('data-image') || '').replace('cdn/list/', 'cdn/thumb/'),
        }
      });
    }
  }

  render() {
    const star1 = (`<use xlink:href="/assets/images/symbol-defs.svg#icon-star"></use>`);
    const star2 = (`<use xlink:href="/assets/images/symbol-defs.svg#icon-star-2"></use>`);
    const props = {
      ...this.state,
      star1: star1,
      star2: star2,
      has: this.has,
      trans: this.trans,
      add: this.props.addFav,
    };
    return (
      <>
        {this.state.meta.button === 'square' && 
          <SquareButton {...props} />
        }
        {this.state.meta.button === 'mobile' && 
          <MobileButton {...props} />
        }
        {['square', 'mobile'].indexOf(this.state.meta.button) === -1 && 
          <StarButton {...props} />
        }
      </>
    );
  }

  private trans = (key: string) =>  this.props.t(key, Favorite.TRANSLATION_KEY);
}

try {

  const mapStateToProps = (state: IKeyValueObject) => {
    return {
      favorites: state.favorites || {},
      translations: state.translations.data
    };
  };

  const mapDispatchToProps = (dispatch: CallableFunction) => {
    return {
      toast: (data: IKeyValueObject) => dispatch(showToast(data)),
      addItemToFavorite: (items: IKeyValueObject[]) => dispatch(addItemToFavoritesAction(items)),
      removeItemFromFavorite: (region: string, id: string) =>  dispatch(removeFavoriteAction(region, id))
    }
  };

  const composedHocs = compose(TranslationHoc, CardActionHoc)(FavoriteButton);
  const Component = connect(mapStateToProps, mapDispatchToProps)(composedHocs);
  const actionButtons = Array.prototype.slice.call(document.querySelectorAll('.favorite-action'), 0);
  
  actionButtons.forEach((el: HTMLElement) => {
    ReactDOM.render(
      <Provider store={container}>
        <Component />
      </Provider>, el
    );
  });

  // jquery plugins initialize
  win('temsa').secondHandToolTip();
} catch (e) {
  console.error(e);
}

