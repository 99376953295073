import { ADD_ITEM_TO_FAVORITES_ACTION, REMOVE_FAVORITE_ACTION, REPLACE_ALL_FAVORITE_ITEMS } from './types';

export interface IAddItemAction {
  [prop: string]: boolean;
}

export function addItemToFavoritesAction(data: IAddItemAction[]) {
  return {
    type: ADD_ITEM_TO_FAVORITES_ACTION,
    data
  }
}
export function replaceAllFavoritesAction(data: IAddItemAction[]) {
  return {
    type: REPLACE_ALL_FAVORITE_ITEMS,
    data
  }
}
export function removeFavoriteAction(region: string, id: string) {
  return {
    type: REMOVE_FAVORITE_ACTION,
    region,
    id
  }
}