import storage from "@temsa/extensions/storage";
import { get, location } from "@temsa/helpers/utils";
import { IKeyValueObject } from "@temsa/interfaces/generic";

export default abstract class Model {
  abstract collection: string;
  public attributes: IKeyValueObject = {};

  constructor(attr: IKeyValueObject) {
    this.attributes = attr;
  }

  public all() {
    return JSON.parse(storage.getItem(this.collection) || '{}');
  }

  public get(): IKeyValueObject[]|undefined {
    return get(location(), this.all());
  }

  public find(id: string, field: string = 'id'): IKeyValueObject|undefined {
    return (this.get() || []).find(item => item[field] === id);
  }

  public has = (id: string): boolean => {
    return !!this.find(id);
  }
}