export const goToFavorites = `
  (function() {
    $('#mobile-navigation-button').trigger('click');
    $('a[href="#mobile-nav-sub-section-2"]').trigger('click');
  })
`;

export const goToComparison = `
  (function() {
    $('#mobile-navigation-button').trigger('click');
    $('a[href="#mobile-nav-sub-section-3"]').trigger('click');
  })
`;