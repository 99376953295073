import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IKeyValueObject } from '@temsa/interfaces/generic';
import TranslationHoc from '@temsa/react/hocs/TranslationHoc';
import AlertModal from './AlertModal';
import { ITranslationHocProps } from '@temsa/react/hocs/TranslationHoc';
import { win } from '@temsa/helpers/globals';
import { close, IModalAction } from '../actions/modal';

type ModalAllProps = IModalProps & ITranslationHocProps;

interface IModalProps {
  modal: IModalAction;
  translations: IKeyValueObject;
  close: (data: IKeyValueObject) => any;
}

interface IModalState {
  open: boolean;
}

class Modal extends React.Component<ModalAllProps, any> {
  public state: Readonly<IModalState> = {
    open: false,
  };

  constructor(props: ModalAllProps) {
    super(props);
  }

  public componentDidUpdate(prevProps: ModalAllProps) {
    if (this.props.modal.open === true) {
      win('$')('#alertModalCenter').modal('show');
      win('$')('.modal').off('hidden.bs.modal');
      win('$')('.modal').on('hidden.bs.modal', (e: any) => {
        this.props.close({open: false});
      });
    }
  }

  public render() {
    return (
      <>
      <AlertModal {...this.props.modal} />
      </>
    );
  }
}

const mapStateToProps = (state: IKeyValueObject) => {
  return {
    modal: state.modal || {},
    translations: state.translations.data
  };
};

const mapDispatchToProps = (dispatch: CallableFunction) => {
  return {
    close: (data: IKeyValueObject) => dispatch(close(data))
  }
};

const composedHocs = compose(TranslationHoc)(Modal);

export default connect(mapStateToProps, mapDispatchToProps)(composedHocs);