export default {
  FEATURED_BRAND_NAME: 'TEMSA',
  states: {
    prod: [
      'www.temsacertified.com',
      'temsacertified.com'
    ],
    stage: [
      'web-certified.efabsrv.com'
    ],
    dev: [
      'www.dev.local:8443',
      'www.dev.local',
    ]
  },
  moneySymbols: {
    TRY: '₺',
    EUR: '€',
    GBP: '£',
    USD: '$'
  },
  moneyFormats: {
    TRY: ['.', ',', '%s %v'],
    EUR: ['.', ',', '%s %v'],
    GBP: ['.', ',', '%s %v'],
    USD: [',', '.', '%s %v']
  },
  translations: {
    favorites: {
      added: '5c88ea1ca1fefd41512453fb',
      removed: '5c890198a1fefd4151245422'
    },
    toast: {
      favorites: {
        title: '5c89024fa1fefd41563af26e'
      },
    },
    comparision: '5ccaeb4deb25a3416e30e7a8',
    advertisementListTranslations: '5c9135bba1fefd168f546b5f'
  },
  mapRelationToModel: {
    'tr-city-relation-to-models': '5c90e057a1fefd168f546ac7',
    'eu-city-relation-to-models': '5c90e2cca1fefd059610d36d',
    'eu-intercity-relation-to-models': '5c90dd28a1fefd574b78a8d5',
    'tr-intercity-relation-to-models': '5c90d656a1fefd059610d313',
    'us-coach-relation-to-models': '5c90e3d9a1fefd168f546aea'
  },
  mapRegionToWeightUnit: {
    tr: 'KG',
    us: 'LBS',
    eu: 'KG',
    en: 'KG'
  },
  mapRegionToMileage: {
    tr: 'KM',
    us: 'MILE',
    eu: 'KM',
    en: 'MILE'
  },
  euroNormRegions: ['tr', 'eu'],
  social: {
    twitter: {
      username: '',
      share_url: 'http://www.twitter.com/share'
    },
    facebook: {
      share_url: 'https://www.facebook.com/sharer/sharer.php'
    },
    whatsapp: {
      share_url: 'https://web.whatsapp.com/send'
    }
  },
}