import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux'
import { addComponent } from '../../helpers/redux';
import container from './../reducers';

class Search extends React.Component {
  state = {keyword: ''};

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({text: 'Lorem ipsum sit dolar amet xxx'})
    }, 3000);
    
  }
  render() {
    return (
      <form onSubmit={(e) => this.handleSubmit({...e})}>
      {this.state.keyword}
        <svg className="icon icon-search" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-search" />' }} />
        <input 
          type="text" 
          name="keyword"
          onChange={(e) => this.handleChange({...e})} 
          value={this.state.keyword} 
          className="form-control" 
          placeholder="İlan no veya model ile ara..." 
        />
      </form>
    );
  }

  
  private handleChange = (e: any) => {
    this.setState({[e.target.name]: e.target.value});
  }
  
  private handleSubmit = (e: any) => {
    console.log(e);
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    active: ownProps.filter === state.visibilityFilter
  }
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    onClick: () => {
      dispatch(() => ownProps.filter)
    }
  }
};

// try {
//   const Component = connect(mapStateToProps, mapDispatchToProps)(Search);
//   const search = ReactDOM.render(
//     <Provider store={container}>
//       <Component />
//     </Provider>, document.getElementById('search')
//   );
  
//   addComponent({ search });

// } catch (e) {
//   console.error('Search->', e);
// }
