import { ReducerType, ActionType, StateType } from './index';
import { OPEN_MODAL, CLOSE_MODAL } from '../actions/types';

const defaultState = {};

const modal: ReducerType<StateType, any> = (state: StateType = defaultState, action: ActionType) => {
  
  switch(action.type) {
    case OPEN_MODAL: {
      return {
        ...defaultState,
        ...action.data,
        open: true
      }
    }
    case CLOSE_MODAL: {
      return {
        open: false
      }
    }
    default:
      return state;
  }
}

export default modal;