import * as React from 'react';
import BaseService from '@temsa/react/services/baseService';
import collectionService from '@temsa/react/services/collectionService';
import { win, store } from '@temsa/helpers/globals';
import { IKeyValueObject, IHttpResponse } from '@temsa/interfaces/generic';
import { get } from '@temsa/helpers/utils';
import { addTranslationPackageAction } from '../actions/translations';
import envService from '@temsa/react/services/envService';

export interface ITranslationHocProps {
  trans: IKeyValueObject;
  t: (key: string, transCollectionKey: string) => string;
  getTranslations: (collectionId: string, storageKey: string) => any;
}

export default function TranslationHoc(WrappedComponent: any) {
  return class extends React.Component<any, any> {
    private collectionService: BaseService = collectionService.newInstance();

    constructor(props: any) {
      super(props);
      this.state = {};
      this.collectionService.setNamespace(`${win('_language')}-${win('_region')}/api/lookup`);
    }

    public getTranslations = (collectionId: string, storageKey: string) => {
      const trans = sessionStorage.getItem(storageKey);
      const translation = (trans ? JSON.parse(trans) : trans) || this.props.translation;

      if (!!translation) {
        this.setState({trans: translation});
        store().dispatch(addTranslationPackageAction({[storageKey]: translation}));
        return;
      }

      this.collectionService.show(collectionId, {additionalUri: ['items']}).then((res: IHttpResponse) => {
        const trans = res.data.data.reduce((result: IKeyValueObject, item: IKeyValueObject) => {
          result[item.slug] = get(`translations.${win('_language')}`, item);
          return result;
        }, {});
  
        this.setState({trans});
        store().dispatch(addTranslationPackageAction({[storageKey]: trans}));
        
        if (envService.get('name') === 'production') {
          sessionStorage.setItem(storageKey, JSON.stringify(trans));
        }
      });
    }

    public get = () => (key: string, transCollectionKey: string) => {
      return get(key, get(transCollectionKey, this.props.translations));
    };

    public render() {
      return (
        <WrappedComponent 
          t={this.get()} 
          {...this.props} 
          {...this.state} 
          getTranslations={this.getTranslations} 
        />
      );
    }
  };
}
