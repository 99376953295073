import { ReducerType, StateType } from './index';
import { IRemoveQueryAction, IAddQueryAction, IAddTagAction, IRemoveTagAction, IAddSidebarParamsActionProps } from '../actions/filter';
import { 
  ADD_QUERY_PARAMETERS_ACTION, 
  REMOVE_QUERY_PARAMETERS_ACTION, 
  ADD_TAG_PARAMETERS_ACTION, 
  REMOVE_TAG_PARAMETERS_ACTION,
  ADD_SIDEBAR_PARAMETERS_ACTION,
  REPLACE_ALL_QUERY_PARAMETERS_ACTION,
  REPLACE_ALL_TAG_PARAMETERS_ACTION
} from '../actions/types';
import { IKeyValueObject, IAction } from '@temsa/interfaces/generic';
import { has, get } from '@temsa/utils';

const mapPairParams = {
  'price-ranges': ['price_min', 'price_max'],
  'gvwr-ranges': ['gvwr_min', 'gvwr_max'],
  'km-ranges': ['km_min', 'km_max'],
  'rg-ranges': ['registration_date_min', 'registration_date_max'],
  'seats-ranges': ['seats_min', 'seats_max'],
  'year': ['year'],
  'city': ['city'],
  'engine_power': ['engine_power'],
  'fuel_type': ['fuel_type'],
  'seats_layout': ['seats_layout'],
  'gearing_type': ['gearing_type'],
  'color': ['color'],
  'condition': ['condition'],
  'euro_norm': ['euro_norm'],
  'models': ['models'],
  'brand': ['brand']
}

type SearchParametersType = IRemoveQueryAction & IAddQueryAction;

const defaultState = {}
const filterParameters: ReducerType<StateType, any> = (state: StateType = defaultState, action: SearchParametersType) => {
  
  switch(action.type) {
    case ADD_QUERY_PARAMETERS_ACTION: {
      return {
        ...state,
        ...action.parameters
      }
    }
    case REPLACE_ALL_QUERY_PARAMETERS_ACTION: {
      return {...action.parameters};
    }
    case REMOVE_QUERY_PARAMETERS_ACTION: {
      const prevState = {...state};
      let newState = {};
      
      if (has(action.id, mapPairParams)) {
        for (const field of get(action.id, mapPairParams)) {
          delete prevState[field];
        }
        newState = {...prevState};
      } else {
        if (!!Array.isArray(prevState[action.id])) {
          delete prevState[name];
          newState = {...prevState};
        } else {
          newState = Object.keys(prevState).reduce((result: IKeyValueObject, name: string) => {
            if (!has(name, mapPairParams)) {
              return result;
            }
            const index = prevState[name].indexOf(action.id);
            if (index > -1) {
              delete prevState[name][index];
              result[name] = [...prevState[name].filter(Boolean)];
            }
            return {...result};
          }, {});
        }
      }
      return {...prevState, ...newState};
    }
    default:
      return state;
  }
}

type SearchTagsType = IAddTagAction & IRemoveTagAction;
const defaultTagState = [] as IKeyValueObject[];
const tagParameters: ReducerType<StateType[], any> = (state: StateType[] = defaultTagState, action: SearchTagsType) => {
  switch(action.type) {
    case ADD_TAG_PARAMETERS_ACTION: {
      let newState = [...state] as IKeyValueObject[];
      action.keys.forEach(key => newState = newState.filter(tag => tag.filter !== key));
      return [
        ...newState,
        ...action.tags
      ]
    }
    case REMOVE_TAG_PARAMETERS_ACTION: {
      const newState = [...state].filter(tag => tag.id !== action.id);
      return [...newState];
    }
    case REPLACE_ALL_TAG_PARAMETERS_ACTION: {
      return [...action.tags];
    }
    default:
      return state;
  }
};

export interface IAddSidebarParamsAction extends IAction {
  parameters: IAddSidebarParamsActionProps;
}

const defaultSidebarState = {};
const sidebarParameters: ReducerType<StateType, IAddSidebarParamsAction> = (state: StateType = defaultSidebarState, action: IAddSidebarParamsAction) => {
  switch(action.type) {
    case ADD_SIDEBAR_PARAMETERS_ACTION: {
      return  {
        ...state, 
        ...action.parameters
      };
    }
    default:
      return state;
  }
};

export { filterParameters, tagParameters, sidebarParameters };